<template>
  <div class="background-small visible-block">
    <div class="f sb mobile">
      <div>
        <h4 class="text-white">{{ title }}</h4>
      </div>
      <div class="f g20">
        <div style="width: 180px">
          <h6 class="text-white">{{ typeTitle }}</h6>
          <SelectorDropdown
            :refFunction="setType"
            :options="typeOptions"
            :selectedItem="selectedType"
          />
        </div>
        <div>
          <h6 class="text-white mb-1">{{ priceProviderTitle }}</h6>
          <CheckboxDropdown
            :options="marketOptions"
            :selected-item="selectedMarket"
            :ref-function="setMarketOption"
            :type="'Markets'"
            :width-option="'90%'"
          ></CheckboxDropdown>
        </div>
      </div>
    </div>

    <div class="tab">
      <SearchBar class="" @handleSearch="handleSearch"></SearchBar>
    </div>
    <div class="main-wrapper">
      <div v-if="loading" class="sail__grid skin__scroll">
        <ShimmerCard
          style="min-height: 190px"
          ref="itemCard"
          v-for="index in 12"
          :key="index"
        />
      </div>

      <div v-else class="sail__grid skin__scroll">
        <ItemCard
          ref="item_card"
          v-for="item in items"
          :key="item.asset_id"
          :item="item"
          @removeItem="removeItem"
        />
      </div>

      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
          :pageNumber="pageNumber"
          :totalPages="totalPages"
          @update:pageNumber="pageNumber = $event"
        />
      </div>
    </div>

    <InfoModal
      v-if="showModal"
      :message="infoMessage"
      :duration="infoModalDuration.toString()"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import InfoModal from "../components/modal/InfoModal.vue";
import ItemCard from "@/components/watchlist/WatchlistItem.vue";
import CheckboxDropdown from "@/components/dropdownButtons/CheckboxDropdown.vue";
import upIcon from "../assets/images/asc.png";
import downIcon from "../assets/images/desc.png";
import { exportPdf } from "../utils/invoiceGenerator.js";
import WatchlistService from "@/services/WatchlistService.js";
import store from "@/store/store";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import MarketOptions from "@/core/constants";

export default {
  data() {
    return {
      MarketOptions,
      totalPages: 6,
      loading: true,
      orderIcon: downIcon,
      title: "Watchlist",
      typeTitle: "Type",
      volumeTitle: "Sales Volume",
      marketOptions: [],
      priceProviderTitle:"Price Providers",
      selectedMarket: { name: "", isChecked: false },
      selectedMarkets: [],
      maxSelectedMarket: 4,
      exportTitle: "Export",
      isDropdownOpen: false,
      showModal: false,
      infoMessage: "Item deleted successfully",
      searchTimeout: null,
      sortBy: "name",
      column: "",
      rotation: false,
      sortOrder: "ASC",
      volumeOptions: ["5", "10", "15", "20", "25", "30", "40", "50", "100"],
      selectedVolume: "5",
      typeOptions: [
        "All",
        "Rifle",
        "Shotgun",
        "Sniper Rifle",
        "Agent",
        "Gloves",
        "Container",
        "Knife",
        "Pass",
        "Sticker",
        "Collectible",
        "Pistol",
        "Machinegun",
        "SMG",
        "Patch",
      ],
      selectedType: "All",
      items: [],
      appID: 730,
      pageNumber: 1,
      pageSize: 12,
      search: "",
      infoModalDuration: 5000,
    };
  },
  methods: {
    async setMarketOption(market) {
      const found = this.marketOptions.find((x) => x.id == market.id);
      if (!found) return;

      if (
        !found.isChecked &&
        this.selectedMarkets.length > this.maxSelectedMarket
      ) {
        this.infoMessage = "You can select up to 4 markets!";
        this.showModal = true;
        setTimeout(() => {
          this.showModal = false;
        }, this.infoModalDuration);
        return;
      }

      found.isChecked = !found.isChecked;
    },
    goItem(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    async removeItem(item) {
      const response = await WatchlistService.deleteWatchlist({
        appid: item.app_id,
        market_hash_name: item.name,
      });

      if (!response) return (this.infoMessage = "Deletion Error!");
      this.infoMessage = `${item.name} deleted!`;
      await this.getItems();
      this.modalClick();
    },
    modalClick() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    async createInvoice() {
      this.toggleLoading();
      await exportPdf(this.items, { name: store.state.steamID ?? "X" });
      this.toggleLoading();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    copyText(item) {
      const textToCopy = item.name;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.infoMessage = "Item name coppied.";
          this.showModal = true;
          setTimeout(() => {
            this.showModal = false;
          }, 1500);
        })
        .catch((error) => {
          this.infoMessage = `Error copying text${error}`;
        });
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      this.search = search;
      this.searchTimeout = setTimeout(() => {
        this.getItems();
      }, 1000);
    },
    async getItems() {
      this.loading = true;
      const response = await WatchlistService.getWatchlist({
        appid: this.appID,
        currentPage: this.pageNumber,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        volume: parseInt(this.selectedVolume),
        selectedType: this.selectedType,
        search: this.search,
        markets: this.selectedMarkets,
      });
      if (!response) return (this.items = []);
      this.items = response;
      this.mapItems();
      this.loading = false;
    },
    mapItems() {
      if (!this.items || this.items.length === 0) return;

      this.items.forEach((e) => {
        let markets = [{ name: "Buff.163", fee: 2.5, price: 0 }];

        if (e && e.markets) {
          e.markets.forEach((market) => {
            const index = markets.findIndex((el) => el.name === market.name);
            if (index != -1) {
              markets[index].price = market.price;
            }
          });
          e.markets = markets;
        }
      });
    },
    async handleOptionClick(option) {
      this.pageSize = option;
      await this.getItems();
      this.isDropdownOpen = false;
    },
    async sortByColumn(column) {
      this.setSortBy(column);

      if (this.sortBy === this.column) {
        this.rotation = !this.rotation;
      } else {
        this.rotation = false;
      }

      await this.getItems();
    },
    setSortBy(column) {
      this.sortBy = column;
      this.column = column;
      this.setOrder();
    },
    setOrder() {
      this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
      this.orderIcon = this.sortOrder === "DESC" ? downIcon : upIcon;
    },
    async setVolume(volume) {
      this.selectedVolume = volume;
      this.isDropdownOpen = false;
      await this.getItems();
    },
    async setType(type) {
      this.selectedType = type;
      this.isDropdownOpen = false;
      await this.getItems();
    },
    setMarkets() {
      const checked = [1, 3, 4, 18];
      this.marketOptions = this.MarketOptions.map((x) => {
        return {
          name: x.name,
          id: x.id,
          isChecked: checked.includes(x.id),
        };
      });
    },
  },
  async created() {
    this.setMarkets();
    await this.getItems();
  },
  watch: {
    pageNumber() {
      this.getItems();
    },
    marketOptions: {
      handler() {
        this.selectedMarkets = this.marketOptions
          .filter((x) => x.isChecked)
          .map((x) => x.id);
        this.getItems();
      },
      deep: true,
    },
  },
  components: {
    InfoModal,
    SelectorDropdown,
    SearchBar,
    ItemCard,
    ShimmerCard,
    CheckboxDropdown,
  },
};
</script>
<style scoped>
@import "../assets/style/watchList.css";

.tab {
  justify-content: space-between;
  margin-block: 0.5rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
  border: none;
}

.name-span {
  font-size: 1rem;
}

.table > tbody > tr > td {
  padding-bottom: 1rem;
  padding-left: 1rem;
  position: relative;
  z-index: 2;
}

a .img {
  cursor: pointer;
  width: 80px;
}

@media screen and (min-width: 1600px) {
  .sail__grid {
    grid-template-columns: repeat(9, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .name-span {
    font-size: 0.875rem;
  }

  .mobile {
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
