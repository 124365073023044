import { apiInstance } from "@/utils/ApiUtil";
const baseURL = "/users";

const getUserDetails = async () => {
  try {
    const response = await apiInstance.get(baseURL + "/details");
    return response.data.success ? response.data.user : null;
  } catch (error) {
    return null;
  }
};

const getUserBalances = async () => {
  try {
    const response = await apiInstance.get(baseURL + "/balance");
    return response.data.success ? response.data.items : null;
  } catch (error) {
    return null;
  }
};

const getUserSessions = async () => {
  try {
    const response = await apiInstance.get(baseURL + "/sessions");
    return response.data.success ? response.data.sessions : [];
  } catch (error) {
    return [];
  }
};

const getUserApis = async () => {

  try {
    const response = await apiInstance.get(baseURL + "/get-api");

    if (!response.data.success) return { waxpeer: '', marketcsgo: '', shadowpay: '', steam: '', haloskins: '' };

    const secret = response.data.items;

    return {
      waxpeer: secret.api_wax ?? '',
      marketcsgo: secret.api_market ?? '',
      shadowpay: secret.api_shadow ?? '',
      csfloat: secret.api_csfloat ?? '',
      haloskins: secret.api_haloskins ?? '',
      steam: secret.steam ?? ''
    };

  } catch (error) {
    return { waxpeer: '', marketcsgo: '', shadowpay: '', steam: '', csfloat: '', haloskins: '' }
  }
};

const setUserApi = async (user) => {
  try {
    const response = await apiInstance.post(baseURL + "/set-api", {
      market: user.market,
      api: user.secret
    });
    return response.data.success;
  } catch (error) {
    return false;
  }
};

const setUserTradeLink = async (user) => {
  try {
    const response = await apiInstance.post(baseURL + "/set-trade", {
      trade_link: user.trade
    });
    return response.data.success;
  } catch (error) {
    return false;
  }
};

const setUserMail = async (user) => {
  try {
    const response = await apiInstance.post("/users/set-mail", {
      mail: user.mail
    });
    return response.data.success;
  } catch (error) {
    return false;
  }
};

const UserService = {
  getUserDetails,
  getUserApis,
  setUserApi,
  setUserTradeLink,
  setUserMail,
  getUserSessions,
  getUserBalances
}

export default UserService;