<template>
  <div class="catalog-card catalog-card2 sell_card select_item">
    <div class="remove-div">
      <button @click="removeItem(item)" class="remove-button">
        <VueIcon height="24px" color="#ff0f0f" icon="lets-icons:remove-light" />
      </button>
    </div>
    <a class="catalog-card__img">
      <img
        class="img"
        :src="item.image"
        :style="{ '--drop-shadow-color': item.color }"
      />
    </a>
    <div class="f flex-column als w100 mt-2 p-1">
      <span
        v-if="getType()"
        class="mid-span bold"
        :style="{ color: typeColor }"
        >{{ getType() }}</span
      >
      <span class="name-span" :style="{ color: item.color }">{{
        item.short_name
      }}</span>
      <span
        v-if="item.exterior"
        class="mid-span"
        :style="{ color: item.exterior_color || 'white' }"
        >{{ item.exterior }}</span
      >
    </div>
    <div class="market-prices">
      <div
        class="f sb markets-out"
        v-for="(market, index) in item.prices"
        :key="index"
      >
        <img :src="getMarketIcon(market.market_name)" class="small-img" />
        <span class="price-span">{{
          formatCurrency(market?.price / 1000)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import MarketOptions from "../../core/constants.js";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    childFunction: Function,
  },
  data() {
    return {
      isActive: false,
      typeColor: "white",
      MarketOptions,
    };
  },
  methods: {
    getType() {
      if (this.item.app_id == 440) return false;
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
    async removeItem(item) {
      this.$emit("remove-item", item);
    },
    formatCurrency(value) {
      return value
        ? value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "No Data";
    },
    getMarketIcon(name) {
      const found = this.MarketOptions.find(
        (e) => e.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
      if (found) return found.icon;
    },
  },
  created() {},
};
</script>

<style scoped>
.market-prices {
  width: 100%;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.markets-out {
  width: 100%;
  padding-bottom: 3px;
  border-bottom: 1px solid #8f57d09e;
}

.small-img {
  height: 16px;
  width: 16px;
}

.remove-div {
  position: absolute;
  top: -8px;
  right: -8px;
}

.sm-gap {
  margin-left: 6px;
}

.mid-span {
  font-size: 11px;
  padding: 0;
}

.name-span {
  font-size: 11px;
  padding: 0;
}

.price-span {
  font-size: 11px;
}

.bold {
  font-weight: bold;
}

.img-small {
  width: 20px;
  height: 20px;
  min-width: 0%;
}

.img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.sell_card:hover .img {
  transition: 0.2s;
  filter: drop-shadow(0 0 6px var(--drop-shadow-color));
}

.sell_card:not(hover) .img {
  transition: 0.2s;
}

.sell_card {
  padding: 0.8rem 1rem;
  background: #15142a;
  border-radius: 5px;
  background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
  background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
  cursor: pointer;
  position: relative;
}

.sell_card.active {
  background: #15142a;
  border: 1.5px solid #882a73;
}

.sell_card:hover {
  background: #15142a;
}

.catalog-card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid transparent;
}

.catalog-card__hours {
  font-size: 13px;
  line-height: 0;
  text-align: center;
  color: #8787ab;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.catalog-card__img {
  display: block;
  position: relative;
  width: 80px;
  margin: 0px;
}

.catalog-card2 .catalog-card__img {
  margin-top: 0;
}

.img-market {
  height: 22px;
  width: 22px;
}

.sell-card-title {
  line-height: 20px;
}

.catalog-card__title {
  padding: 0 2px;
}

.catalog-card__title {
  display: block;
  margin-top: 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 10px;
  color: #fff;
  text-align: start;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.catalog-card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  color: #dd1b5f;
  margin-top: 10px;
}

@media screen and (min-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .sell_card {
    height: 100%;
  }
}
</style>
