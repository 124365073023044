<template>
  <AppNavbar v-if="this.$router.currentRoute.value.name !== 'login'" />
  <SideBarMobile
    v-if="this.$router.currentRoute.value.name !== 'login' && isMobile"
  />
  <SideBar
    v-if="this.$router.currentRoute.value.name !== 'login' && !isMobile"
  />
  <router-view :key="$route.fullPath" />
  <SpeedInsights />
  <Analytics />
  <FooterBar />
</template>


<script>
import { SpeedInsights } from "@vercel/speed-insights/vue";
import { Analytics } from '@vercel/analytics/vue';
import { mapGetters } from "vuex";

export default {
  name: "App",
  metaInfo: {
    title: "Steamanalyzer.com",
    titleTemplate: "%s | Steamanalyzer.com",
  },
  components: {
    SpeedInsights,
    Analytics
  },
  computed: {
    ...mapGetters("ui", ["isMobile", "isMenuOpened", "getRouteLoading"]),
  },
};
</script>
<style>
@import "./assets/style/style.css";
</style>
