<template>
  <div class="background-small">
    <div class="row">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 f align-bottom"
      >
        <h4 class="text-white">Sales History</h4>
      </div>
    </div>
    <div class="filter-div">
      <div class="date-wrapper">
        <div class="date">
          <h6 class="text-white">Date Range</h6>
          <VueDatePicker
            v-model="date"
            range
            :partial-range="false"
            :transitions="false"
          />
        </div>
      </div>
      <div class="right">
        <div class="f flex-column flex-start">
          <h6 class="text-white">Game</h6>
          <IconDropdown
            :refFunction="setGame"
            :options="gameOptions"
            :selectedItem="selectedGame"
            :selectedIcon="selectedGameIcon"
          />
        </div>
        <div class="f flex-column flex-start">
          <h6 class="text-white">Buying Market</h6>
          <IconDropdown
            :refFunction="setType"
            :options="MarketOptions"
            :selectedItem="selectedMarket"
            :selectedIcon="selectedIcon"
          />
        </div>
        <div class="f flex-column flex-start">
          <h6 class="text-white">Sort</h6>
          <SortDropdown
            :height-option="'40px'"
            :order-by-function="setOrderBy"
            :options="orderOptions"
            :selectedItem="selectedOrder"
            :selected-dir="selectedDirection"
            :order-dir-function="setOrderDir"
          />
        </div>
        <div class="f flex-column flex-start">
          <h6 class="text-white">Size</h6>
          <SelectorDropdown
            :refFunction="setSize"
            :options="sizeOptions"
            :selectedItem="selectedSize"
          />
        </div>
      </div>
    </div>
    <div class="mobile-date">
      <div class="date">
        <span class="responsive-title-font text-white">Date Range</span>
        <VueDatePicker
          v-model="date"
          range
          :partial-range="false"
          :transitions="false"
        />
      </div>
    </div>
    <div class="button_wrap py-1">
      <button class="custom_button" @click="openModal">
        <span class="responsive-title-font">{{ this.addSale }}</span>
      </button>
      <button class="custom_button" @click="startSaleProcess">
        <span class="responsive-title-font">{{ this.addToSales }}</span>
      </button>
    </div>

    <div class="filter-div-mobile mb-1">
      <IconDropdown
        :refFunction="setGame"
        :options="gameOptions"
        :selectedItem="selectedGame"
        :selectedIcon="selectedGameIcon"
      />
      <IconDropdown
        :refFunction="setType"
        :options="MarketOptions"
        :selectedItem="selectedMarket"
        :selectedIcon="selectedIcon"
      />
      <SortDropdown
        :height-option="'40px'"
        :order-by-function="setOrderBy"
        :options="orderOptions"
        :selectedItem="selectedOrder"
        :selectedIcon="selectedIcon"
        :selected-dir="selectedDirection"
        :order-dir-function="setOrderDir"
      />
    </div>

    <div class="wrapper pt-2">
      <div class="search-tab">
        <SearchBar class="my-2" @handleSearch="handleSearch"></SearchBar>
        <CheckBox
          v-if="!isMobile"
          v-model="filterObject.isBuyPriceZero.isChecked"
        >
          <span class="responsive-title-font">{{
            filterObject.isBuyPriceZero.name
          }}</span>
        </CheckBox>
      </div>
      <div v-if="loading" class="f flex-column g10">
        <ShimmerEffect v-for="index in 6" :key="index" />
      </div>

      <div v-else v-for="(item, index) in items" :key="index">
        <component
          @go-item="goLink"
          :is="isMobile ? 'TableContentMobile' : 'TableContent'"
          @copy-text="copyText"
          @update-market="updateMarket"
          @update-price="updatePrice"
          @start-editing-price="startEditingPrice"
          :selected-market="selectedMarket"
          :buy="getMarketIcon(item.sell_market)"
          :item="item"
        />
      </div>
      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
          :pageNumber="filterObject.pageNumber"
          :totalPages="totalPages"
          @update:pageNumber="filterObject.pageNumber = $event"
        />
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="copyMessage"
    @close="showModal = false"
  />
  <ErrorModal
    v-if="showErrorModal"
    :message="errorMessage"
    @close="showErrorModal = false"
  />
  <SaleModal v-if="showSaleModal" @closeModal="closeModal" />
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import SaleService from "@/services/SaleService";
import DateUtils from "@/utils/DateUtils";
import InfoModal from "../components/modal/InfoModal.vue";
import SaleModal from "../components/modal/AddSaleModal.vue";
import SideBar from "@/components/shared/SideBar.vue";
import TableContent from "@/components/tableComponents/TableContent.vue";
import TableContentMobile from "@/components/tableComponents/TableContentMobile.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";

import buffIcon from "../assets/marketIcons/buff_icon.png";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";
import tf2 from "../assets/images/tf2.png";
import rust from "../assets/images/rust.png";
import MarketOptions from "../core/constants.js";
import CheckBox from "@/components/checkbox/CheckBox.vue";

export default {
  data() {
    return {
      MarketOptions,
      totalPages: 6,
      searchQuery: "",
      copyMessage: "Price Successfully Updated",
      dropName: "Buy Market",
      loading: false,
      dropTitle: "Buy Market:",
      btnSetMarket: "Set Market",
      isDropdownOpen: false,
      errorMessage: "Try Again Later!",
      showErrorModal: false,
      modalShowingTime: 5000,
      sortBy: "date",
      items: [
        {
          newPrice: null,
        },
      ],
      searchTimeout: null,
      showSaleModal: false,
      showModal: false,
      pageSize: 10,
      sortOrder: "DESC",
      startDate: new Date(),
      endDate: new Date(),
      addToSales: "Sync Sales",
      addSale: "New Sale",
      date: new Date(),
      filterObject: {
        appID: 730,
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        isBuyPriceZero: {
          name: "Show items with 0 price",
          isChecked: false,
        },
      },
      selectedIcon: buffIcon,
      selectedMarket: "Buff.163",
      columns: [
        "Img",
        "Name",
        "Buy Market",
        "Sell Market",
        "Buy Price",
        "Sell Price",
        "Profit",
        "Ratio (%)",
        "Date",
      ],
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },
      ],
      selectedGameIcon: csgo,
      selectedGame: "CS2",
      sizeOptions: ["10", "15", "25", "30", "50"],
      selectedSize: "10",
      orderOptions: ["Date", "Price", "Duration", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      game: 730,
    };
  },
  methods: {
    showErrModal() {
      this.showErrorModal = true;
      setTimeout(() => {
        this.showErrorModal = false;
      }, this.modalShowingTime);
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      await this.getSaleHistory();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getSaleHistory();
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.filterObject.appID = type.appID;
      this.isDropdownOpen = false;
      await this.getSaleHistory();
    },
    setType(type) {
      this.selectedMarket = type.name;
      this.selectedIcon = type.icon;
      this.isDropdownOpen = false;
    },
    async setSize(size) {
      this.selectedSize = size;
      this.filterObject.pageSize = size;
      this.isDropdownOpen = false;
      await this.getSaleHistory();
    },
    startEditingPrice(item) {
      item.editingPrice = true;
      item.newPrice = item.buy_price / 1000;
    },
    async savePrice(item) {
      await this.updatePrice(item);
    },
    async updatePrice(item) {
      try {
        const control = await SaleService.updateBuyPrice({
          price: item.newPrice * 1000,
          item_id: item.item_id,
        });

        item.price = item.newPrice;
        item.editingPrice = false;

        if (!control) {
          item.editingPrice = false;
          item.buy_price = 0;
          await this.getSaleHistory();
          return;
        }

        item.buy_price = item.newPrice;
        item.editingPrice = false;
        this.copyMessage = "Price changed successfully.";
        this.showInfoModal();
        await this.getSaleHistory();
      } catch (error) {
        console.error("Error updating price:", error);
      }
    },
    openModal() {
      this.showSaleModal = true;
    },
    closeModal(control) {
      this.showSaleModal = false;
      if (control === true) return this.showSaleInfoModal();
    },
    async showSaleInfoModal() {
      this.copyMessage = "Item Added Successfully!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 3000);
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.copyMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 1500);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      if (!search) return;
      this.searchQuery = search;
      this.searchTimeout = setTimeout(() => {
        this.getSaleHistory();
      }, 1000);
    },
    extractDate() {
      this.startDate = moment(this.date[0]).format("YYYY-MM-DD");
      this.endDate = moment(this.date[1]).format("YYYY-MM-DD");
    },
    async saleProcessFunction() {
      const response = await SaleService.saleProcess({
        start: this.startDate,
        end: moment(this.endDate).format("YYYY-MM-DD"),
        orderBy: this.filterObject.orderDir,
      });

      if (response && !response?.success && response.code === "INVALID_API") {
        this.errorMessage = "You need to set your API keys on User page.";
        this.showErrModal();
      }
    },
    async startSaleProcess() {
      this.loading = true;
      this.extractDate();
      await this.saleProcessFunction();
      await this.getSaleHistory();
      this.loading = false;
    },
    async getSaleHistory() {
      this.loading = true;
      let response = await SaleService.getSales({
        currentPage: this.filterObject.pageNumber,
        pageSize: this.filterObject.pageSize,
        sortBy: this.filterObject.orderBy,
        sortOrder: this.filterObject.orderDir,
        searchQuery: this.searchQuery,
        appId: this.filterObject.appID,
        purchaseZero: this.filterObject.isBuyPriceZero.isChecked ? 1 : 0,
      });
      this.items = response.items;
      this.loading = false;
    },
    async updateMarket(item) {
      this.loading = true;
      let response = await SaleService.updateBuyMarket({
        item_id: item.item_id,
        market: this.selectedMarket,
      });
      await this.getSaleHistory();
      this.loading = false;
      if (response) return true;
    },
    getMarketIcon(name) {
      const found = this.MarketOptions.find(
        (e) => e.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
      if (found) return found.icon;
    },
    convertDate(date) {
      return DateUtils.getDate(date);
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    const dateNow = new Date();
    const endDate = new Date(new Date().setDate(dateNow.getDate() + 1));
    const startDate = new Date(new Date().setDate(endDate.getDate() - 2));
    this.date = [startDate, endDate];
  },
  watch: {
    filterObject: {
      async handler() {
        this.getSaleHistory();
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    InfoModal,
    SaleModal,
    SideBar,
    IconDropdown,
    TableContent,
    TableContentMobile,
    ShimmerEffect,
    SortDropdown,
    SelectorDropdown,
    SearchBar,
    CheckBox,
  },
  computed: {
    ...mapGetters("ui", ["isMobile"]),
  },
};
</script>
<style>
@import "../assets/style/customTable.css";

h6 {
  margin-bottom: 2px !important;
}

h4 {
  margin-bottom: 0px !important;
}
.page_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bx {
  color: white;
}

.sBtn-text {
  color: white;
}

.search-tab {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.mobile-date {
  display: none;
}

.button_wrap {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
}

.custom_button {
  width: 130px !important;
  border-radius: 1px !important;
  border: 1px rgb(134, 80, 172) solid !important;
}

.date {
  margin-right: 10px;
  width: 100%;
  max-width: 400px;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.btn_copyname {
  border: 0;
  padding: 0;
  border-radius: 0.4rem;
  outline: none;
  background: rgba(0, 0, 0, 0);
  font-size: inherit;
  line-height: 1.2;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
}

.custom-drop-alt {
  background-color: #4d555d;
  font-size: 15px;
  font-weight: 500;
  /* height: 50px; */
  width: 200px;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.img-mid {
  height: 35px !important;
  width: 35px !important;
}

.img-item {
  height: 75px !important;
  width: 90px !important;
}

.filter-div-mobile {
  display: none;
}

.filter-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}

.filter-div .filter-left {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

@media screen and (max-width: 905px) {
  .mobile-date {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  .date {
    max-width: 100% !important;
    margin-right: 0;
  }

  .search {
    width: 90%;
  }

  .filter-div {
    display: none;
  }

  .icon-select-menu .select-btn {
    padding: 10px 5px;
    gap: 5px;
    color: red;
  }

  .selector-menu {
    width: auto;
  }

  .select-menu .select-btn {
    padding: 5px 5px;
  }

  .sort-select-menu {
    width: auto;
  }

  .custom_button {
    padding: 0;
  }

  .custom_button {
    width: 100% !important;
  }

  .filter-div-mobile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }

  .button_wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
