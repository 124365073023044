<template>
  <div
    class="info-modal"
    :style="{ backgroundColor: type === 'info' ? greenAccent : redAccent }"
  >
    <div class="info-modal-content f sb align-center">
      <div>
        <h6>{{ title }}</h6>
        <p class="text">{{ message }}</p>
      </div>
      <div>
        <VueIcon height="32px" color="white" :icon="getIcon()" />
      </div>
    </div>
    <div class="loading-bar">
      <div
        class="fill"
        :style="{
          backgroundColor: type === 'info' ? greenColor : redColor,
          animationDuration: duration,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Success",
    },
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "info",
    },
    duration: {
      type: String,
      required: false,
      default: "4s",
    },
  },
  methods: {
    getIcon() {
      if (this.type == "info") return "mdi-light:check-circle";
      return "mdi:cancel-circle-outline";
    },
  },
  data() {
    return {
      greenColor: "#76ff03",
      redColor: "#cb0000",
      greenAccent: "#2B9F84",
      redAccent: "rgba(230, 62, 62, 0.95)",
    };
  },
};
</script>

<style scoped>
h6 {
  font-weight: 600;
}

.info-modal {
  width: 300px;
  height: 110px;
  position: fixed;
  bottom: 50px;
  right: 30px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.9rem 1rem 0.9rem;
}
.text {
  color: white;
  padding-top: 2px;
  font-weight: 500;
  margin-bottom: 0;
}

.loading-bar {
  position: relative;
  height: 10px;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 4px;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #76ff03;
  animation: fillAnimation normal;
}

@keyframes fillAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
